<template>
  <div class='question-list-container'>
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item v-for="(item, index) in list" :key="index" :name="index">
        <template v-slot:title>
          <p class="title"><i>Q: </i>{{item.title}}</p>
        </template>
        <div>{{item.content}}</div>

      </el-collapse-item>

    </el-collapse>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        activeName: '',
        list: [
          { title: '如何注册?', content: '首先微信搜索小程序“海鲸洗衣”，会跳出微信提示，点击允许后，点击我的-立即等，进行微信授权-允许即可。' },
          { title: '如何自助洗衣？', content: '使用微信扫一扫，扫描洗衣机右上角二维码即可。' },
          { title: '如何充值？', content: '进入小程序“海鲸洗衣”，点击个人中心-洗衣卡，选择对应的卡包进行充值。' },
          { title: '一个账号可以同时使用几台机器？', content: '同一账号可以同时使用多台洗衣机。' },
          { title: '洗衣机下单成功并已扣款，但启动失败？', content: '默认下载成功后，系统会自动启动洗衣机，若未正常启动，您可进入小程序查看订单当前状态，若5分钟之内机器任然未启动，系统将自动为客户取消并退款，若订单状态为已开始且已经超过5分钟，您可通过系统问题反馈反馈给客服，由客服人工取消订单并48小时内退款。' },
          { title: '洗衣过程中断电或停水？', content: '洗衣过程中断电或停水，原理上短时间的停水停电恢复后，需将门盖打开之后合上，系统自动恢复洗涤，若较长时间断电断水，系统无法判断，则无法恢复洗涤程序，您可通过系统问题反馈反馈给客服，由客服人工取消订单并退款。然后再次下单即可。' },
          { title: '扫码之后显示设备运行中，实际无人使用？', content: '可通过系统问题反馈反馈问题，通知客服人员及时重置状态，并建议换一台机器使用。' },
          { title: '扫码之后显示设备不在线？', content: '确认是否停电，电源是否插好，或者信号是否稳定或者显示FC5，您可尝试关闭电源重启，若不行，建议更换机器。同时通过系统问题反馈反馈问题。' },
          { title: '扫码之后显示机器处于异常状态？', content: '确认洗衣机盖是否打开状态，若处于打开状态，先关上洗衣盖后，再次尝试。' },
          { title: '显示错误代码E0 E4？', content: '该异常为进水超时，开合门盖一次可以解除故障。' },
          { title: '显示错误代码E1？', content: '该异常为排水超时 开合门盖一次可以解除故障。' },
          { title: '显示错误代码E2？', content: '该异常为脱水撞桶 开合门盖一次可以解除故障。' },
          { title: '显示错误代码E3？', content: '该异常为开盖报警 开合门盖一次可以解除故障。' },
          { title: '显示错误代码 F1？', content: '该异常为水位传感器报警 请报维修，更换设备洗涤。' },
          { title: '显示错误代码 F5？', content: '该异常为溢水报警 请报维修，更换设备洗涤。' },


        ]
      }
    },
    methods: {
    },
    created() {
      document.title = '常见问题'
    },
    mounted() {
    }
  }
</script>
<style scoped lang='scss'>
  .question-list-container {
    padding: 20px;

    .title {
      i {
        font-style: normal;
        color: #ff5722;
        font-weight: bold;
      }
    }
  }
</style>
